(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:Poblacions
   *
   * @description
   *
   */
  angular
  .module('neo.services')
  .factory('receptores', receptores);

  function receptores($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/receptores');
  }
}());/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


